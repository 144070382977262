import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Terms from "../../components/terms.js"

const TermsPage = () => (
	<Layout>
		<SEO title={"Terms & Conditions of Use"} />

		<div className="background-light-gray padding-vertical">
			<Terms />
		</div>
	</Layout>
)

export default TermsPage
