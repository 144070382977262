import React from 'react';

class Terms extends React.Component {
	render() {
		return (
			<div className="grid-container">
				<div className="sub terms bright-links">
					<a href="#"></a>

					<h1>Terms &amp; Conditions of Use</h1>

					<p>Welcome to AtData’s web site, FreeListCheck.com (the "Site"). By using the Site, you agree to be bound
						by the following terms and all applicable laws and regulations. In addition, you agree to use the site
						only as permitted by law and you agree to indemnify FreshAddress, LLC dba AtData (“AtData”) against any and
						all claims by third parties arising out of your use of the Site in violation of these Terms and
						Conditions of Use ("Terms and Conditions"). If you do not agree to these Terms and Conditions, you
						are not permitted to use the Site.</p>

					<h3>Site Content</h3>

					<p>The Site will examine a provided list of email addresses and provide an overall assessment of list
						quality in the form of a report (the “Content”). AtData owns all worldwide right, title, and interest,
						including all intellectual property and other proprietary rights, in and to the Content, the Site,
						and the software and technology used by AtData to power the Site, its features and functionality,
						and all usage and other data generated or collected in connection with the use thereof
						(“AtData Materials”). You agree not to license, distribute, print, copy, reproduce, modify, publicly
						perform or display, distribute, transmit, publish, edit, adapt, create derivative works from, mirror
						the AtData Materials on any other server, transfer the AtData Materials to any third party, or otherwise
						provide to any third party or make any unauthorized use of the AtData Materials.</p>

					<p>ATDATA MAKES NO WARRANTIES OR REPRESENTATIONS TO YOU THAT YOUR USE OF THE ATDATA
						MATERIALS WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES.</p>

					<h3>Use of Site</h3>

					<p>As a condition of your use of the Site, you will not use the Site for any purpose that is unlawful or
						prohibited by these Terms and Conditions. You may not use the Site in any manner that in our sole
						discretion could damage, disable, overburden, impair, or interfere with any other party's use of it.
						You may not obtain or attempt to obtain any materials or information through any means not intentionally
						made available through the Site. You agree not to scrape or otherwise use automated means to access or
						gather information from the Site, and you agree not to bypass any robot exclusion headers we may put
						into place. In addition, you agree to not use false or misleading information in connection with your
						account and you acknowledge that we reserve the right to disable any account for any reason including
						that the account may be associated with information which we reasonably believe is false or misleading.</p>

					<p>You shall not upload or cause to be uploaded to the Site any virus or other computer software routine or
						hardware components which are designed to (i) permit or not permit access or use by third parties to the
						Site; (ii) disable or damage hardware or damage, erase or delay access to software or data, or (iii)
						perform any other similar actions.</p>

					<h3>Additional Terms</h3>

					<p>When you use certain features or materials on the Site, or participate in a particular promotion, event,
						or contest through the Site, such use or participation may be subject to additional terms and conditions
						posted on the Site. Such additional terms and conditions are hereby incorporated within these Terms and
						Conditions, and you agree to comply with such additional terms and conditions with respect to such use
						or participation.</p>

					<h3>Arbitration</h3>

					<p>Any claim, other than claims by AtData for non-payment, in which the total amount of the award sought by
						either party is less than $25,000 shall be resolved via binding non-appearance-based arbitration initiated
						through the American Arbitration Association ("AAA"). The AAA Rules are available online at www.adr.org or
						by calling the AAA at 1-800-778-7879. In any such arbitration, the parties and AAA must comply with the
						following rules: (a) the arbitration shall be conducted by telephone or online and/or be solely based on
						written submissions, the specific manner shall be chosen by the party initiating the arbitration; (b) the
						arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise
						mutually agreed by the parties; (c) the arbitrator may award injunctive or declaratory relief only in
						favor of the individual party seeking relief and only to the extent necessary to provide relief warranted
						by that party's individual claim; and (d) any judgment on the award rendered by the arbitrator may be
						entered in any court of competent jurisdiction.</p>

					<h3>Class Action Waiver</h3>

					<p>YOU AGREE THAT ANY CLAIMS SUBJECT TO ARBITRATION MUST BE MADE IN YOUR INDIVIDUAL OR COMPANY CAPACITY,
						AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.</p>

					<h3>Claims</h3>

					<p>YOU AND ATDATA AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS AND
						CONDITIONS OR THE SERVICE OR SITE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
						ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>

					<h3>Transfer or Assignment</h3>

					<p>Any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be
						assigned by AtData without restriction.</p>

					<h3>Privacy Policy</h3>

					<p>Any confidential data including but not limited to computer programs, software development, business
						information, names, email addresses, web sites, as well as other information which is proprietary to
						you and/or AtData (“Confidential Information”) uploaded to the site will be kept confidential. AtData
						will not disclose any Confidential Information received by it to third parties, other than necessary
						service providers, without the prior written permission of the other party.</p>

					<p>AtData's Privacy Policy explains how we treat your personal data and protect your privacy when you use
						our Site. By using our Site, you agree that AtData can use such data in accordance with our privacy
						policies. The AtData Privacy Policy can be found here: http://www.AtData.com/about-us/privacy-policy/</p>

					<h3>Liability for our Service</h3>

					<p>IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF ATDATA AND ITS AFFILIATES, CONTRACTORS, EMPLOYEES,
						OFFICERS, DIRECTORS, AGENTS, OR THIRD PARTY PARTNERS, SUPPLIERS, OR DISTRIBUTORS TO YOU FOR ALL DAMAGES,
						LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO YOUR USE OF THE SITE, INCLUDING FOR ANY IMPLIED
						WARRANTIES, EXCEED THE PRICE PAID BY YOU IN THE PRIOR MONTH FOR SUCH USE OF THE SITE.</p>

					<h3>Indemnification.</h3>

					<p>You agree to indemnify and hold AtData and its affiliates, contractors, employees, officers, directors,
						agents, or third party partners, suppliers, or distributors harmless from and against any claims, losses,
						damages, liabilities, costs and expenses, including reasonable attorney's fees (any of the foregoing, a
						"Claim"), that any of them may incur arising out of or relating to your use or misuse of the Site, or
						violation of any law, rule, or regulation, provided that the foregoing does not obligate you to the extent
						the Claim arises directly and solely out of AtData's willful misconduct or gross negligence. AtData
						reserves the right, at its own expense, to assume the exclusive defense and control of any matter for
						which you are required to indemnify us and you agree to cooperate with our defense of these claims.</p>

					<h3>Third Party Web Sites</h3>

					<p>AtData may provide links and pointers to Internet sites maintained by others ("Third Party
						Sites"). AtData has not reviewed these Third Party Sites linked to the Site and is not
						responsible for the content of or any products or services offered on such Third Party Sites.</p>

					<h3>Submitted Information</h3>

					<p>If you submit any information to AtData, including any comments, remarks, suggestions, ideas, notes,
						drawings, graphics, concepts, or other information, you are giving that information, and all your rights
						in it, to AtData free of charge, and that information will be treated as non-confidential and
						non-proprietary and may be used by AtData for any purpose, without your consent or any compensation to
						you or anyone else. This is true whether you submit such information to AtData by e-mail, through a form
						on the Site, on a bulletin board, or in any other manner.</p>

					<p>You agree not to submit or transmit any material that is unlawful, threatening, libelous, defamatory,
						obscene, pornographic, profane, or might in any other way violate any law, regulation, or rule. You are
						solely responsible for any material you submit to the Site. You further agree not to upload, e-mail,
						post or transmit to, or distribute or otherwise publish through the Site any material which disrupts
						the normal operation of the Site, including posting or otherwise transmitting material that is not
						related to the subject at issue or otherwise restricts or inhibits any other user from using the Site.</p>

					<p>Through your usage of the Site, you may submit and/or AtData may gather certain limited
						information about you and your web site usage. AtData is free to use such information for any
						purpose it deems appropriate, including marketing purposes.</p>

					<h3>Disclaimer and Limitation of Liability</h3>

					<p>ALL MATERIAL, INCLUDING ATDATA MATERIALS, ON THIS SITE IS PROVIDED “AS IS”AND WITHOUT WARRANTIES OF ANY
						KIND, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
						MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF
						NON-INFRINGEMENT OF THIRD PARTY RIGHTS. ATDATA MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY,
						COMPLETENESS, OR SUITABILITY OF ANY OF THE MATERIAL, INCLUDING ATDATA MATERIALS, ON THIS SITE, OR ON ANY
						SITE OR SITES "LINKED" TO THIS SITE. ATDATA MAKES NO WARRANTY THAT THE SITE WILL BE AVAILABLE,
						UNINTERRUPTED, ERROR FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ATDATA MAKES NO WARRANTIES
						OR REPRESENTATIONS TO YOU THAT YOUR USE OF THE ATDATA MATERIALS WILL NOT INFRINGE THE RIGHTS OF THIRD
						PARTIES.</p>

					<p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL ATDATA OR ITS AFFILIATES
						HAVE ANY LIABILITY TO YOU OR TO ANY THIRD PARTY FOR CLAIMS ARISING OUT OF OR RELATED TO YOUR USE OF
						ATDATA MATERIALS, REGARDLESS OF THE THEORY OF LIABILITY ON WHICH SUCH CLAIM IS BASED, WHETHER CONTRACT,
						TORT, OR OTHERWISE. ATDATA SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
						INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS
						OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
						DAMAGES) (WHETHER IN CONTRACT, NEGLIGENCE, TORT OR OTHERWISE) ARISING OUT OF OR IN CONNECTION WITH YOUR
						USE OF THE SITE OR INABILITY TO USE THE SITE, SITE CONTENT OR ANY PRODUCTS OR SERVICES, OR ANY FAILURE
						OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, EFFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
						VIRUS, LINE SYSTEM FAILURE, LOSS OF DATA, OR LOSS OF USE RELATED TO THE SITE OR ANY WEB SITE OPERATED
						BY ANY THIRD PARTY. IF YOU ARE DISSATISFIED WITH THE SITE, ANY OF ATDATA'S SERVICES OR THESE TERMS AND
						CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SITE.</p>

					<p>The Site is intended for informational purposes only.</p>

					<h3>United States Only</h3>

					<p>All AtData Materials are provided solely for the purpose of promoting AtData operations and services in
						the United States and its territories. AtData makes no representation that the AtData Materials are
						appropriate or available for use in other locations. If despite these conditions, you use the Site from
						outside the United States, you are solely responsible for compliance with any applicable local laws.</p>

					<h3>Trademarks, Trade Names, and Service Marks</h3>

					<p>Unless otherwise indicated, all logos, names, and marks on the Site are trademarks or servicemarks
						owned or used under license by AtData. The use or misuse of any logos, names, and servicemarks
						or trademarks is strictly prohibited.</p>

					<h3>Modification and Termination of These Terms and Conditions</h3>

					<p>AtData may terminate these Terms and Conditions, terminate your access to all or part of the Site, or
						suspend any user's access to all or part of the Site, at any time, without notice to you, if it believes,
						in its sole judgment, that you have breached or may breach any term or condition of these Terms and
						Conditions, or for its convenience. You may terminate these Terms and Conditions at any time by destroying
						all materials received from the Site and ceasing to use the Site, however, you will remain liable to
						AtData for any actions by you in violation of the Terms and Conditions.</p>

					<p>AtData may modify these Terms and Conditions at any time without notice to you. AtData reserves the
						right to modify these Terms and Conditions and/or its policies relating to the Site at any time,
						effective upon posting of an updated version of these Terms and Conditions. You are responsible for
						regularly reviewing these Terms and Conditions. Changes will not apply retroactively and will become
						effective no sooner than five days after they are posted. However, changes addressing new functions
						for the Site or changes made for legal reasons will be effective immediately. If you do not agree to
						the modified terms for our Site, you should discontinue your use of our Site.</p>

					<h3>Governing Law</h3>

					<p>Any controversy or claim arising out of or relating to these Terms and Conditions, the formation of these
						Terms and Conditions, or the breach of these Terms and Conditions, including any claim based upon or
						arising from an alleged tort, shall be governed by the substantive laws of the Commonwealth of
						Massachusetts without reference to principles of conflicts of law. The United Nations Convention on
						Contracts for the International Sale of Goods does not apply to these Terms and Conditions. EXCEPT
						AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS AND CONDITIONS, ANY SUIT, ACTION OR PROCEEDING CONCERNING
						THESE TERMS AND CONDITIONS MUST BE BROUGHT IN A MASSACHUSETTS STATE OR FEDERAL COURT LOCATED IN MIDDLESEX
						COUNTY, MASSACHUSETTS AND EACH OF THE PARTIES HEREBY IRREVOCABLY CONSENTS TO THE EXCLUSIVE JURISDICTION
						OF SUCH COURTS (AND OF THE APPROPRIATE APPELLATE COURTS THEREFROM) IN ANY SUCH SUIT, ACTION OR PROCEEDING
						AND IRREVOCABLY WAIVES, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,</p>

					<p>ANY OBJECTION WHICH IT MAY NOW OR HEREAFTER HAVE TO THE LAYING OF THE VENUE OF ANY SUCH SUIT, ACTION
						OR PROCEEDING IN ANY SUCH COURT OR THAT ANY SUCH SUIT, ACTION OR PROCEEDING WHICH IS BROUGHT IN ANY
						SUCH COURT HAS BEEN BROUGHT IN AN INCONVENIENT FORUM.</p>

					<h3>Miscellaneous Terms</h3>

					<p>AtData's failure to strictly enforce any right against you shall not constitute a waiver
						thereof. If any provision of these Terms and Conditions is deemed invalid, illegal, or
						unenforceable, such provision will be deemed amended to conform to the applicable laws and the
						remainder of these Terms and Conditions shall remain in full force and effect. This represents the
						full understanding of the parties with respect to the subject matter hereof.</p>

					<h3>Contact</h3>

					<p>
						AtData has a designated individual who is responsible for the internal supervision of
						AtData's terms and privacy policies. You may contact us at:<br/><br/>

						AtData<br/>
						770 Legacy Place<br />
						2nd Floor<br />
						Dedham, MA 02026<br/>
						Email: <a href="mailto:privacy@atdata.com">privacy@AtData.com</a><br/>
					</p>

					<h3>Acceptance of these Terms and Conditions</h3>

					<p>If you are accepting these Terms and Conditions on behalf of a legal entity, you represent and
						warrant that you have the authority to bind that legal entity to these Terms and Conditions. If you
						do not have such authority, you must not accept these Terms and Conditions, in which case you may
						not use the Site.</p>
				</div>
			</div>
		);
	}
}

export default Terms;
